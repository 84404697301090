import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import {
  RadioGroup, Radio, FormControlLabel, TextField, Avatar,
} from '@mui/material';
import { NETWORK_IDS, NETWORKS, USER_TYPES } from 'lib/constants';
import { useApi } from 'lib/contexts/ApplicationState';
import { useSnackbar } from 'notistack';
import { toNumber, isEmpty } from 'lodash';
import { UserToSave } from 'lib/types/users';
import { imageAvatarUrl } from 'lib/utils/imageUtils';
import { NetworkIcon } from 'elements/NetworkIcon';
import { Feedback } from 'components/Feedback';
import { useModal } from 'mui-modal-provider';

interface Props {
  onSave: (account: UserToSave, type: number) => Promise<void>;
  onCancel: () => void;
  username?: string;
}

interface SocialOptions {
  twitter: UserToSave;
  instagram: UserToSave;
  tiktok: UserToSave;
  existingUser: UserToSave;
  twitch: UserToSave;
  youtube: UserToSave;
  facebook: UserToSave;
  threads: UserToSave;
}

export const NewUserToCreditModal = ({
  onSave, onCancel, username,
}: Props) => {
  const API = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();

  const [_username, setUsername] = useState(username);
  const [selected, setSelected] = useState<keyof SocialOptions | null>(null);
  const [userType, setUserType] = useState(USER_TYPES.creator.id);
  const [options, setOptions] = useState<SocialOptions>();
  const [isSearching, setIsSearching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const notFound = (result: SocialOptions) => {
    if (!result) return true;

    return isEmpty(result.existingUser) && isEmpty(result.instagram)
      && isEmpty(result.tiktok) && isEmpty(result.twitter) && isEmpty(result.youtube)
      && isEmpty(result.facebook) && isEmpty(result.threads);
  };

  const search = async () => {
    setOptions(undefined);
    setSelected(null);
    const regEx = new RegExp(/^[\w.]+$/i);
    if (!_username || !regEx.test(_username)) {
      enqueueSnackbar('Please provide a valid username', {
        variant: 'error',
      });
      return;
    }
    try {
      setIsSearching(true);
      const results = await API.lookUpAllNetworks(_username);
      if (notFound(results)) setSelected(null);
      setOptions(results);
    } catch (err) {
      enqueueSnackbar(`Something went wrong: ${err}`, {
        variant: 'error',
      });
    }
    setIsSearching(false);
  };

  const getOptions = () => {
    if (!options) {
      return null;
    }
    if (notFound(options)) {
      return (
        <div>
          <b>
            @
            {selected}
          </b>
          {' '}
          wasn&apos;t found on any platforms.
          <br />
          If you would like to add them anyway, click Add User.
        </div>
      );
    }
    if (!isEmpty(options.existingUser)) {
      return (socialOption(options.existingUser, 0));
    }

    return (
      <>
        {socialOption(options.twitter, NETWORK_IDS.twitter)}
        {socialOption(options.instagram, NETWORK_IDS.instagram)}
        {socialOption(options.tiktok, NETWORK_IDS.tiktok)}
        {socialOption(options.twitch, NETWORK_IDS.twitch)}
        {socialOption(options.youtube, NETWORK_IDS.youtube)}
        {socialOption(options.facebook, NETWORK_IDS.facebook)}
        {socialOption(options.threads, NETWORK_IDS.threads)}
      </>
    );
  };

  const socialOption = (option: UserToSave, networkId: number) => {
    if (isEmpty(option)) {
      return null;
    }

    return (
      <FormControlLabel
        value={NETWORKS[networkId].name}
        label={(
          <div className="flex items-center gap-4 py-2">
            {
              networkId === 0
                ? <img alt="logo" src="/red-logo.png" width={24} height={24} />
                : <NetworkIcon network={networkId} iconColor="color" />
            }
            <Avatar
              sx={{ width: 48, height: 48 }}
              alt={option.username}
              src={option.avatarUrl && imageAvatarUrl(option.avatarUrl)}
            />
            <div className="text-sm">
              <div className="font-semibold">
                @
                {option.username}
              </div>
              <div>
                {option.name}
              </div>
            </div>
          </div>
        )}
        control={<Radio />}
      />
    );
  };

  const save = async () => {
    if (!options || !selected) return;
    setIsSaving(true);
    const selectedAccount = options[selected];
    const userTypeId = toNumber(userType);
    if (selectedAccount) {
      await onSave(selectedAccount, userTypeId);
      setIsSaving(false);
    }
  };

  const handleModal = () => {
    const modal = showModal(Feedback, {
      onClose: () => {
        modal.destroy();
      },
      title: 'Report an issue',
      text: 'Add a brief description of the bug or platform issue you are experiencing, and how we can reproduce it.',
    });
  };

  const selectedAccount = options && selected && options[selected];

  return (
    <Dialog open maxWidth="md">
      <div className="p-6">
        <div className="absolute right-2 top-2">
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </div>
        <h1 className="text-xl font-semibold mb-2">Add user by social media handle</h1>
        <p className="mb-4">
          We could not find the user in our system.
          Let&apos;s add them, so you can give them some credits!
        </p>
        <div className="flex w-full gap-4 mb-4">
          <TextField
            fullWidth
            autoFocus
            variant="outlined"
            InputProps={{
              startAdornment: '@',
            }}
            label="social media handle"
            placeholder="Search TikTok, Instagram, YouTube, Facebook, Twitch and Twitter handles"
            size="small"
            value={_username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full"
          />
          <button
            disabled={!_username || isSearching}
            type="button"
            className="btn-primary"
            onClick={search}
          >
            {isSearching ? 'Searching...' : 'Search'}
          </button>
        </div>
        <div className="font-semibold mb-2">{options && !notFound(options) && 'Select an account'}</div>
        {isSearching ? <div className="w-full text-center">Searching...</div>
          : (
            <RadioGroup
              value={selected}
              onChange={(e) => setSelected(e.target.value as keyof SocialOptions)}
            >
              {getOptions()}
            </RadioGroup>
          )}
        {selectedAccount && !selectedAccount?.id // if not gondola user
        && (
          <RadioGroup
            aria-labelledby="type-radio-group"
            name="type-radio-group"
            value={userType}
            onChange={(e) => setUserType(toNumber(e.target.value))}
          >
            <div className="font-semibold mt-2">This account is for a...</div>
            <div className="flex">
              <FormControlLabel
                key={USER_TYPES.creator.key}
                value={USER_TYPES.creator.id}
                control={<Radio />}
                label={<b>{USER_TYPES.creator.key}</b>}
              />
              <FormControlLabel
                key={USER_TYPES.company.key}
                value={USER_TYPES.company.id}
                control={<Radio />}
                label={<b>{USER_TYPES.company.key}</b>}
              />
            </div>
          </RadioGroup>
        ) }
        <div className="flex flex-wrap justify-between items-baseline w-full">
          <div className="w-full sm:w-1/3 mb-2">
            <button type="button" className="btn-primary-outlined-xs" onClick={handleModal}>Need help?</button>
          </div>
          <div className="w-full sm:w-2/3 text-right">
            <button
              className="btn-primary-outlined mr-2"
              onClick={onCancel}
              type="button"
            >
              Cancel
            </button>
            <button
              className="btn-primary"
              disabled={isSearching || !selected || isSaving}
              onClick={save}
              type="button"
            >
              {selectedAccount?.id ? 'Credit User' : 'Add User'}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
